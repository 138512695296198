import React, { Component } from 'react';
import Slider from 'react-slick';
import waybillAir from '../../assets/waybill-air.webp'
import waybillSea from '../../assets/waybill-sea.webp'
import waybillElectronic from '../../assets/waybill-electronic.webp'
import waybillBlog from '../../assets/waybill-blog-house.webp'
import { Link } from 'react-router-dom';

class SpecialServicesCarousel extends Component {

    render() {
        const settings = {
            dots: true,
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 4000,
            beforeChange: (oldIndex, newIndex) => {
                this.setState({ activeIndex: newIndex });
            },
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },

                {
                    breakpoint: 480,
                    settings: {
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 2000,
                        dots: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const items = [
            [
                {
                    src: waybillElectronic,
                    title: 'Electronic Bill of Lading',
                    text: 'An Electronic Bill of Lading is a digital document for the ownership and shipment of goods.',
                    link: 'electronic-bill-of-landing'
                },
            ],
            [
                {
                    src: waybillSea,
                    title: 'Sea Waybill',
                    text: 'A Sea Waybill is a document for shipping goods, serving as a contract and receipt.',
                    link: 'sea-waybill'
                },
            ],
            [
                {
                    src: waybillBlog,
                    title: 'House Bill',
                    text: "A house bill of lading (HBL) is a document issued by a freight forwarder or a non-vessel operating common carrier (NVOCC) to a shipper to acknowledge receipt of their items for shipment. In other words, it's a receipt for the goods.",
                    link: 'house-bill'
                }
            ],
            [
                {
                    src: waybillAir,
                    title: 'Air Waybill',
                    text: 'An air waybill is a document that accompanies goods shipped by an international courier, which allow for tracking.',
                    link: 'air-waybill'
                },
            ],
            [
                {
                    src: waybillElectronic,
                    title: 'Electronic Bill of Lading',
                    text: 'An Electronic Bill of Lading is a digital document for the ownership and shipment of goods.',
                    link: 'electronic-bill-of-landing'
                },
            ],
            [
                {
                    src: waybillSea,
                    title: 'Sea Waybill',
                    text: 'A Sea Waybill is a document for shipping goods, serving as a contract and receipt.',
                    link: 'sea-waybill'
                },
            ],
            [
                {
                    src: waybillBlog,
                    title: 'House Bill',
                    text: "A house bill of lading (HBL) is a document issued by a freight forwarder or a non-vessel operating common carrier (NVOCC) to a shipper to acknowledge receipt of their items for shipment. In other words, it's a receipt for the goods.",
                    link: 'house-bill'
                }
            ],
            [
                {
                    src: waybillAir,
                    title: 'Air Waybill',
                    text: 'An air waybill is a document that accompanies goods shipped by an international courier, which allow for tracking.',
                    link: 'air-waybill'
                },
            ],
            [
                {
                    src: waybillElectronic,
                    title: 'Electronic Bill of Lading',
                    text: 'An Electronic Bill of Lading is a digital document for the ownership and shipment of goods.',
                    link: 'electronic-bill-of-landing'
                },
            ],
            [
                {
                    src: waybillSea,
                    title: 'Sea Waybill',
                    text: 'A Sea Waybill is a document for shipping goods, serving as a contract and receipt.',
                    link: 'sea-waybill'
                },
            ],
            [
                {
                    src: waybillBlog,
                    title: 'House Bill',
                    text: "A house bill of lading (HBL) is a document issued by a freight forwarder or a non-vessel operating common carrier (NVOCC) to a shipper to acknowledge receipt of their items for shipment. In other words, it's a receipt for the goods.",
                    link: 'house-bill'
                }
            ],
            [
                {
                    src: waybillAir,
                    title: 'Air Waybill',
                    text: 'An air waybill is a document that accompanies goods shipped by an international courier, which allow for tracking.',
                    link: 'air-waybill'
                },
            ],
        ];

        return (
            <div className="container custom-carousel specialServices-slide">
                <div className="row">
                    <div className="col-md-4 special-services">
                        <h2>Special Services</h2>
                        <p>
                            Globally known for our ability to handle every last detail of our customers’
                            particular logistics and forwarding needs, Transportation Special Services
                            team takes care of all your logistics.
                        </p>

                    </div>
                    <div className="col-md-8">
                        <Slider {...settings} ref={this.slickRef}>
                            {items.map((group, index) => (
                                <div key={`SpecialServiceSlider-${index}`} className="d-flex justify-content-between">
                                    {group.map((item, idx) => (
                                        <div key={`SpecialServiceSlider-${index}-${idx}`} className="card">
                                            <img src={item.src} alt={item.title} className="card-img-top" />
                                            <div className="card-body">
                                                <h5 className="card-title">{item.title}</h5>
                                                <p className="card-text">{item.text}</p>
                                                <Link to={`/blog/${item.link}`} className="btn btn-link">Read more &rarr;</Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpecialServicesCarousel;
