import React, { Component } from 'react'
import { Input } from 'reactstrap'
import slideImg1 from '../../assets/slide-1.webp'
import slideImg2 from '../../assets/slide-2.webp'
import slideImg3 from '../../assets/slide-3.webp'
import slideImg4 from '../../assets/slide-4.webp'
import slideImg7 from '../../assets/slideImg4.webp'
import slideImg5 from '../../assets/slideImg5.webp'
import slideImg6 from '../../assets/slideImg6.webp'
import productTrack from '../../assets/man-in-supermarket.jpg'
import Navbars from '../../Navbar/Navbar';
import { withRouter } from 'react-router-dom';
import VerticalCarousel from './VerticalSlider'
import { Link } from 'react-router-dom'


class RegisterTrackSection extends Component {
    state = {
        refno: '',
        trackBtnClicked: false
    }
    goToTracking = () => {
        if (this.state.refno) {
            this.props.history.push('/trackShipment/' + this.state.refno)
        }
        this.setState({ trackBtnClicked: true })
    }
    render() {
        const slides = [
            { src: slideImg3, alt: 'First slide' },
            { src: slideImg1, alt: 'Second slide' },
            { src: slideImg4, alt: 'Third slide' },
            { src: slideImg2, alt: 'Fourth slide' },
            { src: slideImg7, alt: 'Fifth slide' },
            { src: slideImg5, alt: 'Sixth slide' },
            { src: slideImg6, alt: 'Seventh slide' },
            { src: productTrack, alt: 'Eighth slide' }
        ];

        return (
            <div className="register-track-section">
                <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        {slides.map((slide, index) => (
                            <div key={`Home-${slide.alt}`} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                <img className="d-block w-100 homepage-bg-style img-fluid" src={slide.src} alt={slide.alt} />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="layer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Navbars />
                            </div>
                            <div className="col-12">
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <div className='home-banner-content'>
                                            <h1>Seamless Cargo And Parcel Handling Solution For Everyone</h1>
                                            <Link to='/verifyEmail'>
                                                <h3><span>Register Now</span> for free and Instantly gain access to a wide range of parcel and cargo handling, tracking & delivery features.</h3>
                                            </Link>
                                        </div>
                                        <div className='row-register-track'>
                                            <p>Global Parcel Tracking. Index 1000+ Carriers, Airlines and Sealines</p>
                                            <div className="tracking-form">
                                                <Input
                                                    className="form-control mr-1"
                                                    type="text"
                                                    placeholder="Enter Tracking Number or Container Number"
                                                    onChange={(e) => this.setState({ refno: e.target.value })}
                                                    name="name" value={this.state.refno} />
                                                <button className="btn btn-primary" onClick={() => this.goToTracking()}>Track Now</button>
                                            </div>
                                            {this.state.trackBtnClicked && this.state.refno.trim() === '' &&
                                                <p className='track-error'>Tracking Number or Container Number required</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-4 d-flex align-items-end'>
                                        <VerticalCarousel />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(RegisterTrackSection);