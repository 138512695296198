import React, { Component } from 'react'
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import "./dropStyle.css"
import { deleteUserAccount, lockUserAccount, requestMobileNoVerifyRequest } from '../../api/Form';
import { toast } from 'react-toastify';

export class DropMenu extends Component {

    undoVerifyPhone = async (row) => {
        const data = await localStorage.getItem('auth');
        const alldata = JSON.parse(data);
        requestMobileNoVerifyRequest({
            admin_id: alldata.id,
            user_id: row.idUsers,
            phone_number: row.phoneUsers,
            request_status: 2,
            verified_by: 0
        }).then((response) => {
            toast.success(response.data.message);
            this.props.getAllUSersHandler()
        }).catch(err => {
            toast.error(err.response.data.message);
            this.setState({ loading: false });
        });
    }

    requestVerifyPhone = async (row) => {
        const data = await localStorage.getItem('auth');
        const alldata = JSON.parse(data);
        requestMobileNoVerifyRequest({
            admin_id: alldata.id,
            user_id: row.idUsers,
            phone_number: row.phoneUsers,
            request_status: 1,
            verified_by: 0
        }).then((response) => {
            toast.success(response.data.message);
            this.props.getAllUSersHandler()
        }).catch(err => {
            toast.error(err.response.data.message);
            this.setState({ loading: false });
        });
    }

    verifyPhone = async (row) => {
        const data = await localStorage.getItem('auth');
        const alldata = JSON.parse(data);
        requestMobileNoVerifyRequest({
            admin_id: alldata.id,
            user_id: row.idUsers,
            phone_number: row.phoneUsers,
            request_status: row.request_status ? row.request_status : 0,
            verified_by: 1
        }).then((response) => {
            toast.success(response.data.message);
            this.props.getAllUSersHandler()
        }).catch(err => {
            toast.error(err.response.data.message);
            this.setState({ loading: false });
        });
    }

    lockAccount = async (row, is_locked) => {
        lockUserAccount({
            ad_code: row.uuid,
            user_id: Number(row.idUsers),
            account_type: row.accounttypeUsers,
            is_locked: is_locked,
        }).then((response) => {
            toast.success(response.data.message);
            this.props.getAllUSersHandler()
        }).catch(err => {
            toast.error(err.response.data.message);
            this.setState({ loading: false });
        });
    }

    deleteAccount = async (row) => {
        deleteUserAccount({
            user_id: Number(row.idUsers),
            account_type: row.accounttypeUsers
        }).then((response) => {
            toast.success(response.data.message);
            this.props.getAllUSersHandler()
        }).catch(err => {
            toast.error(err.response.data.message);
            this.setState({ loading: false });
        });
    }

    render() {
        const { row } = this.props
        return (
            <div>
                <UncontrolledDropdown className="me-2 head-frop-button" direction="down" >
                    <DropdownToggle className="drop-button-table" >
                        <i className='fa fa-ellipsis-v' style={{ fontSize: "18px" }} role='button' id='generate-temp'></i>
                    </DropdownToggle>
                    <DropdownMenu className="menuOfDrop">
                        {
                            Number(row?.verified) !== 1 ?
                                <>
                                    {
                                        Number(row?.request_status) !== 1 &&
                                        (<div>
                                            <button className="btn btn-submit w-100 p-0 menuOfDropBtn1" onClick={() => this.verifyPhone(row)}>Verify Account</button>
                                        </div>)
                                    }
                                </>
                                :
                                null
                        }
                        {
                            Number(row?.verified) !== 1 ?
                                <>
                                    {
                                        Number(row?.request_status) !== 1 &&
                                        (<div>
                                            <button className="btn btn-submit w-100 mt-2 p-0 menuOfDropBtn2" onClick={() => this.requestVerifyPhone(row)}>Request Verification</button>
                                        </div>)
                                    }
                                </>
                                :
                                null
                        }
                        {
                            Number(row?.verified) !== 1 ?
                                <>
                                    {
                                        row?.request_status !== null && Number(row?.request_status) !== 2 &&
                                        (<div>
                                            <button className="btn btn-submit w-100 mt-2 p-0 menuOfDropBtn3" onClick={() => this.undoVerifyPhone(row)}>Decline Verification</button>
                                        </div>)
                                    }
                                </> :
                                null
                        }

                                <div>
                                    <button className="btn btn-submit w-100 mt-2 p-0 menuOfDropBtn4" onClick={() => this.deleteAccount(row)}>Delete Account</button>
                                </div>


                        {
                            Number(row?.is_locked) === 0 &&
                            <div>
                                <button className="btn btn-submit w-100 mt-2 p-0 menuOfDropBtn5" onClick={() => this.lockAccount(row, 1)}>Lock Account</button>
                            </div>
                        }
                        {
                            Number(row?.is_locked) === 1 &&
                            <div>
                                <button className="btn btn-submit w-100 mt-2 p-0 menuOfDropBtn6" onClick={() => this.lockAccount(row, 0)}>Unlock Account</button>
                            </div>
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        )
    }
}

export default DropMenu
