import React, { Component } from 'react'
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import './Signup.css'
import logo from '../assets/4.png'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { signUp } from '../api/auth'
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css'
import classNames from 'classnames';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-intl-tel-input';
import { isValidNumber } from 'libphonenumber-js';
import 'react-intl-tel-input/dist/main.css';

const { REACT_APP_RECAPTCHA_SITEKEY } = process.env;
const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])^(?!.*[\s]).{8,20}$/;

const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;


const SignupSchema = Yup.object().shape({
	username: Yup.string()
		.required('Username is Required'),
	firstname: Yup.string()
		.required('First Name is Required'),
	lastname: Yup.string()
		.required('Last Name is Required'),
	email: Yup.string()
		.required('Email is Required'),
	houseno: Yup.string()
		.required('House No. is Required').max(11, 'Too long!'),
	streetname: Yup.string()
		.required('Street Name is Required'),
	suburb: Yup.string()
		.required('Suburb is Required'),
	state: Yup.string()
		.required('State is Required'),
	postcode: Yup.string()
		.required('Post code is Required').max(11, 'Too long!'),
	country: Yup.string()
		.required('Country is Required'),
	phone: Yup.string()
		.required('Phone No. is Required'),
	accounttype: Yup.string()
		.required('Account Type is Required'),
	businessname: Yup.string()
		.when("accounttype", {
			is: 'Business',
			then: Yup.string().required("Business Name is Required")
		}),
	businesstype: Yup.string()
		.when("accounttype", {
			is: 'Business',
			then: Yup.string().required("Business Type is Required")
		}),
	addressdescription: Yup.string()
		.required('Address Description is Required'),
	password: Yup
		.string()
		.matches(passwordRules, { message: "Password must contain eight to twenty characters including one number, symbol, lowercase & uppercase letter, and no blank spaces." })
		.required("Required"),
	passwordRepeat: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Password mismatch'),
	unitno: Yup.string().max(11, 'Too long!'),
	phone: Yup.string().required('Phone No. is Required'),
});


const options = [
	{ value: 'Afghanistan', label: 'Afghanistan' },
	{ value: 'Aland Islands', label: 'Aland Islands' },
	{ value: 'Albania', label: 'Albania' },
	{ value: 'Algeria', label: 'Algeria' },
	{ value: 'American Samoa', label: 'American Samoa' },
	{ value: 'AndorrA', label: 'AndorrA' },
	{ value: 'Angola', label: 'Angola' },
	{ value: 'Anguilla', label: 'Anguilla' },
	{ value: 'Antarctica', label: 'Antarctica' },
	{ value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
	{ value: 'Argentina', label: 'Argentina' },
	{ value: 'Armenia', label: 'Armenia' },
	{ value: 'Aruba', label: 'Aruba' },
	{ value: 'Australia', label: 'Australia' },
	{ value: 'Austria', label: 'Australia' },
	{ value: 'Azerbaijan', label: 'Azerbaijan' },
	{ value: 'Bahamas', label: 'Bahamas' },
	{ value: 'Bahrain', label: 'Bahrain' },
	{ value: 'Bangladesh', label: 'Bangladesh' },
	{ value: 'Barbados', label: 'Barbados' },
	{ value: 'Belarus', label: 'Belarus' },
	{ value: 'Belgium', label: 'Belgium' },
	{ value: 'Belize', label: 'Belize' },
	{ value: 'Benin', label: 'Benin' },
	{ value: 'Bermuda', label: 'Bermuda' },
	{ value: 'Bhutan', label: 'Bhutan' },
	{ value: 'Bolivia', label: 'Bolivia' },
	{ value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
	{ value: 'Botswana', label: 'Botswana' },
	{ value: 'Bouvet Island', label: 'Bouvet Island' },
	{ value: 'Brazil', label: 'Brazil' },
	{ value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
	{ value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
	{ value: 'Bulgaria', label: 'Bulgaria' },
	{ value: 'Burkina Faso', label: 'Burkina Faso' },
	{ value: 'Burundi', label: 'Burundi' },
	{ value: 'Cambodia', label: 'Cambodia' },
	{ value: 'Cameroon', label: 'Cameroon' },
	{ value: 'Canada', label: 'Canada' },
	{ value: 'Cape Verde', label: 'Cape Verde' },
	{ value: 'Cayman Islands', label: 'Cayman Islands' },
	{ value: 'Central African Republic', label: 'Central African Republic' },
	{ value: 'Chad', label: 'Chad' },
	{ value: 'Chile', label: 'Chile' },
	{ value: 'China', label: 'China' },
	{ value: 'Christmas Island', label: 'Christmas Island' },
	{ value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
	{ value: 'Colombia', label: 'Colombia' },
	{ value: 'Comoros', label: 'Comoros' },
	{ value: 'Congo', label: 'Congo' },
	{ value: 'Congo, The Democratic Republic of the', label: 'Congo, The Democratic Republic of the' },
	{ value: 'Cook Islands', label: 'Cook Islands' },
	{ value: 'Costa Rica', label: 'Costa Rica' },
	{ value: "Cote DIvoire", label: "Cote D'Ivoire" },
	{ value: 'Croatia', label: 'Croatia' },
	{ value: 'Cuba', label: 'Cuba' },
	{ value: 'Cyprus', label: 'Cyprus' },
	{ value: 'Czech Republic', label: 'Czech Republic' },
	{ value: 'Denmark', label: 'Denmark' },
	{ value: 'Djibouti', label: 'Djibouti' },
	{ value: 'Dominica', label: 'Dominica' },
	{ value: 'Dominican Republic', label: 'Dominican Republic' },
	{ value: 'Ecuador', label: 'Ecuador' },
	{ value: 'Egypt', label: 'Egypt' },
	{ value: 'El Salvador', label: 'El Salvador' },
	{ value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
	{ value: 'Eritrea', label: 'Eritrea' },
	{ value: 'Estonia', label: 'Estonia' },
	{ value: 'Ethiopia', label: 'Ethiopia' },
	{ value: 'Falkland Islands (Malvinas)', label: 'Falkland Islands (Malvinas)' },
	{ value: 'Faroe Islands', label: 'Faroe Islands' },
	{ value: 'Fiji', label: 'Fiji' },
	{ value: 'Finland', label: 'Finland' },
	{ value: 'France', label: 'France' },
	{ value: 'French Guiana', label: 'French Guiana' },
	{ value: 'French Polynesia', label: 'French Polynesia' },
	{ value: 'French Southern Territories', label: 'French Southern Territories' },
	{ value: 'Gabon', label: 'Gabon' },
	{ value: 'Gambia', label: 'Gambia' },
	{ value: 'Georgia', label: 'Georgia' },
	{ value: 'Germany', label: 'Germany' },
	{ value: 'Ghana', label: 'Ghana' },
	{ value: 'Gibraltar', label: 'Gibraltar' },
	{ value: 'Greece', label: 'Greece' },
	{ value: 'Greenland', label: 'Greenland' },
	{ value: 'Grenada', label: 'Grenada' },
	{ value: 'Guadeloupe', label: 'Guadeloupe' },
	{ value: 'Guam', label: 'Guam' },
	{ value: 'Guatemala', label: 'Guatemala' },
	{ value: 'Guernsey', label: 'Guernsey' },
	{ value: 'Guinea', label: 'Guinea' },
	{ value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
	{ value: 'Guyana', label: 'Guyana' },
	{ value: 'Haiti', label: 'Haiti' },
	{ value: 'Heard Island and Mcdonald Islands', label: 'Heard Island and Mcdonald Islands' },
	{ value: 'Holy See (Vatican City State)', label: 'Holy See (Vatican City State)' },
	{ value: 'Honduras', label: 'Honduras' },
	{ value: 'Hong Kong', label: 'Hong Kong' },
	{ value: 'Hungary', label: 'Hungary' },
	{ value: 'Iceland', label: 'Iceland' },
	{ value: 'India', label: 'India' },
	{ value: 'Indonesia', label: 'Indonesia' },
	{ value: 'Iran, Islamic Republic Of', label: 'Iran, Islamic Republic Of' },
	{ value: 'Iraq', label: 'Iraq' },
	{ value: 'Ireland', label: 'Ireland' },
	{ value: 'Isle of Man', label: 'Isle of Man' },
	{ value: 'Israel', label: 'Israel' },
	{ value: 'Italy', label: 'Italy' },
	{ value: 'Jamaica', label: 'Jamaica' },
	{ value: 'Japan', label: 'Japan' },
	{ value: 'Jersey', label: 'Jersey' },
	{ value: 'Jordan', label: 'Jordan' },
	{ value: 'Kazakhstan', label: 'Kazakhstan' },
	{ value: 'Kenya', label: 'Kenya' },
	{ value: 'Kiribati', label: 'Kiribati' },
	{ value: "Korea, Democratic PeopleS Republic of", label: "Korea, Democratic People'S Republic of" },
	{ value: 'Korea, Republic of', label: 'Korea, Republic of' },
	{ value: 'Kuwait', label: 'Kuwait' },
	{ value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
	{ value: "Lao PeopleS Democratic Republic", label: "Lao People'S Democratic Republic" },
	{ value: 'Latvia', label: 'Latvia' },
	{ value: 'Lebanon', label: 'Lebanon' },
	{ value: 'Lesotho', label: 'Lesotho' },
	{ value: 'Liberia', label: 'Liberia' },
	{ value: 'Libyan Arab Jamahiriya', label: 'Libyan Arab Jamahiriya' },
	{ value: 'Liechtenstein', label: 'Liechtenstein' },
	{ value: 'Lithuania', label: 'Lithuania' },
	{ value: 'Luxembourg', label: 'Luxembourg' },
	{ value: 'Macao', label: 'Macao' },
	{ value: 'Macedonia, The Former Yugoslav Republic of', label: 'Macedonia, The Former Yugoslav Republic of' },
	{ value: 'Madagascar', label: 'Madagascar' },
	{ value: 'Malawi', label: 'Malawi' },
	{ value: 'Malaysia', label: 'Malaysia' },
	{ value: 'Maldives', label: 'Maldives' },
	{ value: 'Mali', label: 'Mali' },
	{ value: 'Malta', label: 'Malta' },
	{ value: 'Marshall Islands', label: 'Marshall Islands' },
	{ value: 'Martinique', label: 'Martinique' },
	{ value: 'Mauritania', label: 'Mauritania' },
	{ value: 'Mauritius', label: 'Mauritius' },
	{ value: 'Mayotte', label: 'Mayotte' },
	{ value: 'Mexico', label: 'Mexico' },
	{ value: 'Micronesia, Federated States of', label: 'Micronesia, Federated States of' },
	{ value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
	{ value: 'Monaco', label: 'Monaco' },
	{ value: 'Mongolia', label: 'Mongolia' },
	{ value: 'Montserrat', label: 'Montserrat' },
	{ value: 'Morocco', label: 'Morocco' },
	{ value: 'Mozambique', label: 'Mozambique' },
	{ value: 'Myanmar', label: 'Myanmar' },
	{ value: 'Namibia', label: 'Namibia' },
	{ value: 'Nauru', label: 'Nauru' },
	{ value: 'Nepal', label: 'Nepal' },
	{ value: 'Netherlands', label: 'Netherlands' },
	{ value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
	{ value: 'New Caledonia', label: 'New Caledonia' },
	{ value: 'New Zealand', label: 'New Zealand' },
	{ value: 'Nicaragua', label: 'Nicaragua' },
	{ value: 'Niger', label: 'Niger' },
	{ value: 'Nigeria', label: 'Nigeria' },
	{ value: 'Niue', label: 'Niue' },
	{ value: 'Norfolk Island', label: 'Norfolk Island' },
	{ value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
	{ value: 'Norway', label: 'Norway' },
	{ value: 'Oman', label: 'Oman' },
	{ value: 'Pakistan', label: 'Pakistan' },
	{ value: 'Palau', label: 'Palau' },
	{ value: 'Palestinian Territory, Occupied', label: 'Palestinian Territory, Occupied' },
	{ value: 'Panama', label: 'Panama' },
	{ value: 'Papua New Guinea', label: 'Papua New Guinea' },
	{ value: 'Paraguay', label: 'Paraguay' },
	{ value: 'Peru', label: 'Peru' },
	{ value: 'Philippines', label: 'Philippines' },
	{ value: 'Pitcairn', label: 'Pitcairn' },
	{ value: 'Poland', label: 'Poland' },
	{ value: 'Portugal', label: 'Portugal' },
	{ value: 'Puerto Rico', label: 'Puerto Rico' },
	{ value: 'Qatar', label: 'Qatar' },
	{ value: 'Reunion', label: 'Reunion' },
	{ value: 'Romania', label: 'Romania' },
	{ value: 'Russian Federation', label: 'Russian Federation' },
	{ value: 'RWANDA', label: 'RWANDA' },
	{ value: 'Saint Helena', label: 'Saint Helena' },
	{ value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
	{ value: 'Saint Lucia', label: 'Saint Lucia' },
	{ value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
	{ value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
	{ value: 'Samoa', label: 'Samoa' },
	{ value: 'San Marino', label: 'San Marino' },
	{ value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
	{ value: 'Saudi Arabia', label: 'Saudi Arabia' },
	{ value: 'Senegal', label: 'Senegal' },
	{ value: 'Serbia and Montenegro', label: 'Serbia and Montenegro' },
	{ value: 'Seychelles', label: 'Seychelles' },
	{ value: 'Sierra Leone', label: 'Sierra Leone' },
	{ value: 'Singapore', label: 'Singapore' },
	{ value: 'Slovakia', label: 'Slovakia' },
	{ value: 'Slovenia', label: 'Slovenia' },
	{ value: 'Solomon Islands', label: 'Solomon Islands' },
	{ value: 'Somalia', label: 'Somalia' },
	{ value: 'South Africa', label: 'South Africa' },
	{ value: 'South Georgia and the South Sandwich Islands', label: 'South Georgia and the South Sandwich Islands' },
	{ value: 'Spain', label: 'Spain' },
	{ value: 'Sri Lanka', label: 'Sri Lanka' },
	{ value: 'Sudan', label: 'Sudan' },
	{ value: 'Suriname', label: 'Suriname' },
	{ value: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
	{ value: 'Swaziland', label: 'Swaziland' },
	{ value: 'Sweden', label: 'Sweden' },
	{ value: 'Switzerland', label: 'Switzerland' },
	{ value: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
	{ value: 'Taiwan, Province of China', label: 'Taiwan, Province of China' },
	{ value: 'Tajikistan', label: 'Tajikistan' },
	{ value: 'Tanzania, United Republic of', label: 'Tanzania' },
	{ value: 'Thailand', label: 'Thailand' },
	{ value: 'Timor-Leste', label: 'Timor-Leste' },
	{ value: 'Togo', label: 'Togo' },
	{ value: 'Tokelau', label: 'Tokelau' },
	{ value: 'Tonga', label: 'Tonga' },
	{ value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
	{ value: 'Tunisia', label: 'Tunisia' },
	{ value: 'Turkey', label: 'Turkey' },
	{ value: 'Turkmenistan', label: 'Turkmenistan' },
	{ value: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
	{ value: 'Tuvalu', label: 'Tuvalu' },
	{ value: 'Uganda', label: 'Uganda' },
	{ value: 'Ukraine', label: 'Ukraine' },
	{ value: 'United Arab Emirates', label: 'United Arab Emirates' },
	{ value: 'United Kingdom', label: 'United Kingdom' },
	{ value: 'United States', label: 'United States' },
	{ value: 'United States Minor Outlying Islands', label: 'United States Minor Outlying Islands' },
	{ value: 'Uruguay', label: 'Uruguay' },
	{ value: 'Uzbekistan', label: 'Uzbekistan' },
	{ value: 'Vanuatu', label: 'Vanuatu' },
	{ value: 'Venezuela', label: 'Venezuela' },
	{ value: 'Viet Nam', label: 'Viet Nam' },
	{ value: 'Virgin Islands, British', label: 'Virgin Islands, British' },
	{ value: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
	{ value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
	{ value: 'Western Sahara', label: 'Western Sahara' },
	{ value: 'Yemen', label: 'Yemen' },
	{ value: 'Zambia', label: 'Zambia' },
	{ value: 'Zimbabwe', label: 'Zimbabwe' }
];


const options1 = [
	{ value: 'Personal', label: 'Personal' },
	{ value: 'Business', label: 'Business' }
];

export default class Forms extends Component {
	state = {
		username: '',
		firstname: '',
		lastname: '',
		email: '',
		unitno: '',
		houseno: '',
		streetname: '',
		suburb: '',
		state: '',
		postcode: '',
		country: '',
		phone: '',
		accounttype: '',
		businessname: '',
		businesstype: '',
		addressdescription: '',
		password: '',
		passwordRepeat: '',
		loading: false,
		acceptTerms: false,
		captchaIsDone: true,
		mobileError: "",
		contySelect: false,
		showError: false,
		isValidPhoneNo: true
	}

	componentDidMount() {
		const { email } = this.props.location.state;
		this.setState({ email: email });
	}

	submitHandler = (values) => {
		if (!this.state.isValidPhoneNo) {
			return toast.warn("Please check your phone number")
		}
		this.setState({ loading: true });
		if (values.acceptTerms) {
			if (values.accounttype === 'Personal') {
				delete values.businessname;
				delete values.businesstype;
			}
			signUp(values)
				.then(Response => {
					this.setState({ loading: false })
					toast.success("Registration successfully completed", {
						position: toast.POSITION.TOP_RIGHT
					});
					setTimeout(
						function () {
							this.props.history.push('./login')
						}
							.bind(this),
						3000
					);

				})
				.catch(err => {
					toast.error(err.response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.setState({ loading: false });
				})
		} else {
			toast.error('The terms and conditions must be accepted.', {
				position: toast.POSITION.TOP_RIGHT
			});
			this.setState({ loading: false });
		}

	}

	captchaHandler = () => {
		this.setState({
			captchaIsDone: false
		})
	}

	handleSelectCountry = (country, countryData) => {
		this.setState({ contySelect: true })
	};

	handlePhoneNumberChange = (isEdited, phoneNumber, selectedCountry, setFieldValue) => {
		const isValid = isValidNumber(phoneNumber, [selectedCountry.iso2.toUpperCase()])
		this.setState({ isValidPhoneNo: isValid });
		const number = `+${selectedCountry.dialCode}${phoneNumber}`
		setFieldValue("phone", number)
	};
	render() {
		var errorMob = ''

		return (
			<div>
				<Formik
					enableReinitialize
					initialValues={{
						username: this.state.username,
						firstname: this.state.firstname,
						lastname: this.state.lastname,
						email: this.state.email,
						unitno: this.state.unitno,
						houseno: this.state.houseno,
						streetname: this.state.streetname,
						suburb: this.state.suburb,
						state: this.state.state,
						postcode: this.state.postcode,
						country: this.state.country,
						phone: this.state.phone,
						accounttype: this.state.accounttype,
						businessname: this.state.businessname,
						businesstype: this.state.businesstype,
						addressdescription: this.state.addressdescription,
						password: this.state.password,
						passwordRepeat: this.state.passwordRepeat,
						acceptTerms: this.state.acceptTerms,
					}}
					validationSchema={SignupSchema}
					onSubmit={(values, { setErrors }) => {
						this.setErrors = setErrors;
						this.submitHandler(values);
					}}
				>{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					handleBlur,
					setFieldValue,
				}) => (
					<div className=" w-100 h-100">
						<div className='container-fluid p0'>
							<div className="row">
								<div className="col-4 d-flex justify-content-center align-items-center">
									<div className="d-none d-md-block d-lg-block d-xl-block signup-bg-style">
										<div className="d-flex justify-content-center">
											<Link to="/">
												<img src={logo} className="img-fluid" alt="" />
											</Link>
										</div>
										<h1 className="pb-3 pt-3 text-center login-title">Postkodes</h1>
										<p className="text-justify verify-description">Revolutionary parcel and product tracking platform using
											the latest IoT technology. Giving your business and your customers improved efficiency and
											productivity at significantly reduced cost when compared to your current system.</p>
									</div>
								</div>
								<div
									className="col-12 col-md-6 col-lg-6 col-xl-6 col-offset-none offset-sm-none offset-md-1 offset-lg-1 offset-xl-1   ">
									<div className="container">
										<div className="d-flex justify-content-center d-block  d-md-none d-lg-none d-xl-none pb-3">
											<a href="/"><img src={logo} alt="" /></a>
										</div>
										<div className="d-none d-md-block d-lg-block d-xl-block">
											<h1 className="text-center py-3">Sign Up</h1>
										</div>
										<div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
											<h1 className="title-style pb-0 text-left">Sign Up</h1>
											<p className=" description-text">Sign up to continue</p>
										</div>
										<form onSubmit={handleSubmit}>
											<div className="row">
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">User Name</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-user icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="text"
																invalid={errors.username && touched.username}
																name="username" onChange={handleChange} onBlur={handleBlur} value={values.username}
																placeholder="Enter User Name" />
															<FormFeedback>
																{errors.username && touched.username && errors.username}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className="">
														<Label for="examplePassword">First Name</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-user icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="text"
																invalid={errors.firstname && touched.firstname}
																name="firstname" onChange={handleChange} onBlur={handleBlur} value={values.firstname}
																placeholder="Enter First Name" />
															<FormFeedback>
																{errors.firstname && touched.firstname && errors.firstname}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">Last Name</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-user icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="text"
																invalid={errors.lastname && touched.lastname}
																name="lastname" onChange={handleChange} onBlur={handleBlur} value={values.lastname}
																placeholder="Enter Last Name" />
															<FormFeedback>
																{errors.lastname && touched.lastname && errors.lastname}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">Unit No. (optional)</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-circle icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="unitNumber"
																invalid={errors.unitno && touched.unitno}
																name="unitno" onChange={handleChange} onBlur={handleBlur} value={values.unitno}
																placeholder="Enter Unit Number" />
															<FormFeedback>
																{errors.unitno && touched.unitno && errors.unitno}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">Email</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="email"
																disabled
																invalid={errors.email && touched.email}
																name="email" onChange={handleChange} onBlur={handleBlur} value={values.email}
																placeholder="Enter Email Address " />
															<FormFeedback>
																{errors.email && touched.email && errors.email}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">House No.</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-home icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="text"
																invalid={errors.houseno && touched.houseno}
																name="houseno" onChange={handleChange} onBlur={handleBlur} value={values.houseno}
																placeholder="Enter House Number " />
															<FormFeedback>
																{errors.houseno && touched.houseno && errors.houseno}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">Street Name</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-street-view icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="text"
																invalid={errors.streetname && touched.streetname}
																name="streetname" onChange={handleChange} onBlur={handleBlur} value={values.streetname}
																placeholder="Enter Street Name" />
															<FormFeedback>
																{errors.streetname && touched.streetname && errors.streetname}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">Suburb OR Town</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-map icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="text"
																invalid={errors.suburb && touched.suburb}
																name="suburb" onChange={handleChange} onBlur={handleBlur} value={values.suburb}
																placeholder="Enter Suburb OR Town" />
															<FormFeedback>
																{errors.suburb && touched.suburb && errors.suburb}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">State</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-globe icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="text"
																invalid={errors.state && touched.state}
																name="state" onChange={handleChange} onBlur={handleBlur} value={values.state}
																placeholder="Enter state" />
															<FormFeedback>
																{errors.state && touched.state && errors.state}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">Post Code</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="text"
																invalid={errors.postcode && touched.postcode}
																name="postcode" onChange={handleChange} onBlur={handleBlur} value={values.postcode}
																placeholder="Enter Post Code" />
															<FormFeedback>
																{errors.postcode && touched.postcode && errors.postcode}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup>
														<Label>Country</Label>
														<Select
															name="country"
															onChange={(option) => setFieldValue('country', option.value)}
															onBlur={handleBlur}
															className="is-invalid"
															value={options ? options.find(option => option.value === values.country) : ''}
															options={options}
														/>
														<FormFeedback>
															{errors.country && touched.country && errors.country}
														</FormFeedback>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">Phone Number</Label>
														<div class="input-group mb-3">
															<PhoneInput
																containerClassName="intl-tel-input"
																inputClassName="form-control"
																onPhoneNumberChange={(isEdited, phoneNumber, selectedCountry) => this.handlePhoneNumberChange(isEdited, phoneNumber, selectedCountry, setFieldValue)}
															/>
															<h5 className='error-display'>
																{!errors.phone && !touched.phone && !this.state.isValidPhoneNo && "Please enter a valid phone number"}
																{errors.phone && touched.phone && errors.phone}
															</h5>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup>
														<Label>Account Type</Label>
														<Select
															name="accounttype"
															onChange={(option) => setFieldValue('accounttype', option.value)}
															onBlur={handleBlur}
															className="is-invalid"
															value={options1 ? options1.find(option => option.value === values.accounttype) : ''}
															options={options1}
														/>
														<FormFeedback>
															{errors.accounttype && touched.accounttype && errors.accounttype}
														</FormFeedback>
													</FormGroup>
												</div>
												{values.accounttype == "Business" ? <>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">Business Name</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-briefcase icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="text"
																	invalid={errors.businessname && touched.businessname}
																	name="businessname" onChange={handleChange} onBlur={handleBlur}
																	value={values.businessname} placeholder="Enter Business Name" />
																<FormFeedback>
																	{errors.businessname && touched.businessname && errors.businessname}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">Business Type</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-building icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="text"
																	invalid={errors.businesstype && touched.businesstype}
																	name="businesstype" onChange={handleChange} onBlur={handleBlur}
																	value={values.businesstype} placeholder="Enter Business Type" />
																<FormFeedback>
																	{errors.businesstype && touched.businesstype && errors.businesstype}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
												</> : null}
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className="">
														<Label for="examplePassword">Password</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-unlock-alt icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="password"
																invalid={errors.password && touched.password}
																name="password" onChange={handleChange} onBlur={handleBlur} value={values.password}
																placeholder="Enter password" />
															<FormFeedback>
																{errors.password && touched.password && errors.password}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
													<FormGroup className="">
														<Label for="examplePassword">Confirm Password</Label>
														<div class="input-group mb-3">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-unlock-alt icon-style"
																	aria-hidden="true"></i></span>
															</div>
															<Input type="password"
																invalid={errors.passwordRepeat && touched.passwordRepeat}
																name="passwordRepeat" onChange={handleChange} onBlur={handleBlur}
																value={values.passwordRepeat} placeholder="Enter Confirm Password" />
															<FormFeedback>
																{errors.passwordRepeat && touched.passwordRepeat && errors.passwordRepeat}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12">
													<FormGroup className=" has-feedback has-feedback-left ">
														<Label for="exampleEmail">Address Description</Label>
														<div class="input-group mb-3">
															<Input type="textarea"
																invalid={errors.addressdescription && touched.addressdescription}
																name="addressdescription" onChange={handleChange} onBlur={handleBlur}
																value={values.addressdescription} placeholder="Enter address description  " />
															<FormFeedback>
																{errors.addressdescription && touched.addressdescription && errors.addressdescription}
															</FormFeedback>
														</div>
													</FormGroup>
												</div>
												<div className="col-12">
													<FormGroup check>
														<Label check>
															<Input type="checkbox" name="acceptTerms" isChecked={values.acceptTerms} placeholder="" onChange={() => setFieldValue('acceptTerms', !values.acceptTerms)} />{' '}
															I agree to the <a style={{ "color": "#007bff" }} href="/terms-conditions" target="_blank">terms and conditions.</a>
														</Label>
													</FormGroup>
												</div>
											</div>
											<ReCAPTCHA sitekey={REACT_APP_RECAPTCHA_SITEKEY} onChange={this.captchaHandler} />
											<div className="pt-3 pb-5">
												<Button disabled={this.state.captchaIsDone} className="btn btn-login btn-block" type="submit">
													{this.state.loading ?
														< ClipLoader
															size={15}
															color={"#123abc"}
															loading={true}
														/> : 'Sign Up'
													}
												</Button>
											</div>
										</form>

									</div>
								</div>
								<div className="col-offset-none offset-sm-none offset-md-1 offset-lg-1 offset-xl-1"></div>
							</div>
						</div>
					</div>
				)}
				</Formik>
			</div>
		)
	}
}
