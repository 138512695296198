import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PostkodesLogo from '../../assets/new_logo.png'
const { REACT_APP_ERRAND_EMAIL } = process.env;

export default class Footer extends Component {

    render() {
        return (
            <>
                <footer>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-2 col-sm-2">
                                <div class="wpb_wrapper">
                                    <img alt='Postkodes' src={PostkodesLogo} className="" width={100} height={100} />
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-2">
                                <h6> Quick Links </h6>
                                <Link to="/requesterrand" className="footer-description">Request Errand</Link>
                                <Link to="/verifyEmail" className="footer-description">Register</Link>
                                <Link to="/forgotPassword" className="footer-description">Reset Password</Link>
                                <Link to="/shipping-calculator" className="footer-description">Shipping Calculator</Link>
                            </div>
                            <div class="col-md-2 col-sm-2">
                                <h6> Services </h6>
                                <Link to="/trackShipment" className="footer-description">Track Parcel</Link>
                                <Link to="/air-freight-services" className="footer-description">Air Freight</Link>
                                <Link to="/sea-freight-services" className="footer-description">Sea Freight</Link>
                                <Link to="/road-freight-services" className="footer-description">Road Freight</Link>
                                <Link to="/errands-services" className="footer-description">Errands Services</Link>
                            </div>

                            <div class="col-md-2 col-sm-2">
                                <h6>Company</h6>
                                <Link to="/about" className="footer-description">About</Link>
                                <Link to="/privacy-policy" className="footer-description">Privacy Policy</Link>
                                <Link to="/terms-conditions" className="footer-description">Terms & Conditions </Link>
                            </div>
                            <div class="col-md-2 col-sm-2">
                                <div class="wpb_wrapper">
                                    <h6>
                                        Contact Us</h6>
                                    <a href={`mailto:${REACT_APP_ERRAND_EMAIL}`}>{REACT_APP_ERRAND_EMAIL}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-description1">
                        <p className="m-0">Postkodes | © All Rights Reserved | 2019-2024</p>
                    </div>
                </footer>
            </>
        )
    }
}
